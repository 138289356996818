<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <ServiceForm ref="editForm" :initial="initial" v-show="edit" class="area-content"></ServiceForm>
      <div class="area-content" v-if="detail" v-show="!edit">
        <Descriptions title="服务基本信息" :list="basicList" :detail="detail" class="mb-24"></Descriptions>
        <div class="mb-24">
          <div class="ant-descriptions-title">招募岗位</div>
          <a-table ref="dataTable" :columns="postColumns" :row-key="(record) => record.id" :data-source="postList">
          </a-table>
        </div>
        <div class="mb-24">
          <div class="ant-descriptions-title">核销人管理</div>
          <a-table ref="dataTable" :columns="columns" :row-key="(record) => record.id" :data-source="list"> </a-table>
        </div>
        <Descriptions title="报名限制条件" :list="limitList" :detail="detail"></Descriptions>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </template>
      <template v-else>
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handleEdit" v-if="detail && detail.status < 3">编辑</a-button>
      </template>
    </a-space>
  </div>
</template>

<script>
import { numberOptions, volunStatusOptions, classesTypeOptions, getServiceStatus } from "../../../common/hr/volunteer";
import { getKeyTitle, isEmpty, timestampConvertString } from "../../../common/js/tool";
import ServiceForm from "./components/ServiceForm.vue";
import Descriptions from "./components/Descriptions.vue";
import { getSystemRoleName } from "../../../permission/subsystem";
// import VolunteerList from './components/VolunteerList.vue';
// import LimitGroupList from './components/LimitGroupList.vue';

export default {
  name: "ServiceDetail",
  components: {
    ServiceForm,
    Descriptions,
    // VolunteerList,
    // LimitGroupList,
  },
  data() {
    return {
      edit: false,
      detail: null,
      initial: null,
      basicList: [],
      limitList: [],
      columns: [
        {
          title: "核销人名称",
          dataIndex: "user_id",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return <open-data type="userName" openid={text[0]} />;
            } else {
              return "-";
            }
          },
        },
        { title: "手机号码", dataIndex: "mobile" },
        {
          title: "核销类别",
          customRender: (text) => {
            return <span>{getSystemRoleName(text.system, text.privilege)}</span>;
          },
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
        showSizeChanger: true,
      },
      postColumns: [
        { title: "岗位名称", dataIndex: "name" },
        { title: "岗位地点", dataIndex: "location" },
        { title: "岗位要求", dataIndex: "info" },
        {
          title: "是否限制岗位人数",
          dataIndex: "is_limit",
          customRender: (text) => {
            return <span>{getKeyTitle(numberOptions, text, "value", "label")}</span>;
          },
        },
        {
          title: "限制人数",
          customRender: (text) => {
            let { is_limit, num } = text;
            if (is_limit == 2) {
              return <span>{num}</span>;
            } else {
              return <span>-</span>;
            }
          },
        },
      ],
      postList: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-service/${this.id}`,
          method: "GET",
          params: {
            expand: "serviceType,volunteerGroup,volunteer,serveClasses,servePost,joinGroup,userRole",
          },
        }).then((res) => {
          getServiceStatus(res);
          this.detail = res;
          if (this.$route.query.edit) {
            this.handleEdit();
          } else {
            this.getDescriptionsList();
          }
        });
      }
    },
    getDescriptionsList() {
      let { userRole, servePost } = this.detail;
      this.postList = servePost;
      let basicList = [];
      let limitList = [];
      basicList.push(
        {
          key: "name",
          label: "服务名称",
        },
        {
          label: "服务类型",
          slot: (detail) => {
            return <span>{detail.serviceType?.name || "-"}</span>;
          },
        },
        {
          label: "服务地点",
          slot: (detail) => {
            let { location, address } = detail;
            location = location.replace(/,/g, "");
            return <span>{location + address}</span>;
          },
        },
        {
          key: "num",
          label: "招募总人数",
        },
        {
          label: "值班小组",
          slot: (detail) => {
            return <span>{detail.volunteerGroup?.name || "-"}</span>;
          },
        },
        {
          label: "值班组长",
          slot: (detail) => {
            return <open-data type="userName" openid={detail.group_leader} />;
          },
        },
        {
          label: "固定服务日",
          slot: (detail) => {
            return <span>{detail.volunteerGroup?.is_fixed == 1 ? detail.volunteerGroup?.week : "-"}</span>;
          },
        },
        {
          label: "服务日期",
          slot: (detail) => {
            return <span>{timestampConvertString(detail.service_date, "yyyy-MM-dd") || "-"}</span>;
          },
        },
        {
          label: "可打卡时间",
          slot: (detail) => {
            let { start_time, end_time } = detail;
            return <span>{start_time + " ~ " + end_time}</span>;
          },
        },
        {
          label: "服务班次",
          slot: (detail) => {
            let { serveClasses } = detail;
            return (
              serveClasses?.map((item, index) => {
                let { type, start_time, end_time } = item;
                return (
                  <div>
                    <span style="margin-right: 6px;">班次{index + 1}:</span>
                    <span>{getKeyTitle(classesTypeOptions, type, "value", "label")}</span>
                    <span style="margin-left: 6px;">{start_time + " ~ " + end_time}</span>
                  </div>
                );
              }) || <span>-</span>
            );
          },
        },
        {
          key: "info",
          label: "服务介绍",
          rich: true,
        }
      );
      limitList.push(
        {
          label: "可进行报名的年龄",
          slot: (detail) => {
            let { min_age, max_age } = detail;
            if (!isEmpty(min_age) && !isEmpty(max_age)) {
              return (
                <div>
                  <span>{min_age}岁</span>
                  <span> ~ </span>
                  <span>{max_age}岁</span>
                </div>
              );
            } else if (!isEmpty(min_age)) {
              return <span>最小{min_age}岁</span>;
            } else if (!isEmpty(max_age)) {
              return <span>最大{max_age}岁</span>;
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          key: "volunteer_status",
          label: "可进行报名的志工状态",
          options: volunStatusOptions,
          tag: true,
        },
        {
          label: "仅已选中的小组可参与",
          slot: (detail) => {
            let { joinGroup } = detail;
            if (joinGroup?.length > 0) {
              return joinGroup.map((item) => <a-tag>{item.volunteerGroup?.name}</a-tag>);
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          label: "每个小组最多报名人数",
          slot: (detail) => {
            let { joinGroup } = detail;
            if (joinGroup?.length > 0) {
              return  <span>{joinGroup[0].enrollment}</span>;
            } else {
              return <span>-</span>;
            }
          },
        }
      );
      this.limitList = limitList;
      this.basicList = basicList;
      this.list = userRole;
    },
    handleEdit() {
      this.edit = true;
      this.initial = this.detail;
    },
    handleSave() {
      this.$refs.editForm.validate().then((form) => {
        this.$axios({
          url: `/admin/volunteer-service/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.getDetail();
          this.handleCancel();
        });
      });
    },
    handleCancel() {
      if (this.$route.query.edit) {
        this.back();
      } else {
        this.edit = false;
        this.initial = null;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
